/* tslint:disable */
/* eslint-disable */
/**
 * Vocalise.API.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProgressDTO,
    ProgressDTOFromJSON,
    ProgressDTOToJSON,
    ProgressItem,
    ProgressItemFromJSON,
    ProgressItemToJSON,
} from '../models';

export interface ApiProgressPostRequest {
    progressItem?: ProgressItem;
}

/**
 *
 */
export class ProgressApi extends runtime.BaseAPI {

    /**
     */
    async apiProgressPostRaw(requestParameters: ApiProgressPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/progress`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProgressItemToJSON(requestParameters.progressItem),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiProgressPost(requestParameters: ApiProgressPostRequest): Promise<void> {
        await this.apiProgressPostRaw(requestParameters);
    }

    /**
     */
    async apiProgressWeekGetRaw(): Promise<runtime.ApiResponse<Array<ProgressDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/progress/week`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProgressDTOFromJSON));
    }

    /**
     */
    async apiProgressWeekGet(): Promise<Array<ProgressDTO>> {
        const response = await this.apiProgressWeekGetRaw();
        return await response.value();
    }

}
