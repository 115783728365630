/* tslint:disable */
/* eslint-disable */
/**
 * Vocalise.API.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Entitlement,
    EntitlementFromJSON,
    EntitlementFromJSONTyped,
    EntitlementToJSON,
    NonSubscription,
    NonSubscriptionFromJSON,
    NonSubscriptionFromJSONTyped,
    NonSubscriptionToJSON,
    Subscription,
    SubscriptionFromJSON,
    SubscriptionFromJSONTyped,
    SubscriptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface Subscriber
 */
export interface Subscriber {
    /**
     * 
     * @type {Date}
     * @memberof Subscriber
     */
    firstSeen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Subscriber
     */
    lastSeen?: Date;
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    managementUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    originalAppUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    originalApplicationVersion?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Subscriber
     */
    originalPurchaseDate?: Date | null;
    /**
     * 
     * @type {{ [key: string]: Entitlement; }}
     * @memberof Subscriber
     */
    entitlements?: { [key: string]: Entitlement; } | null;
    /**
     * 
     * @type {{ [key: string]: Subscription; }}
     * @memberof Subscriber
     */
    subscriptions?: { [key: string]: Subscription; } | null;
    /**
     * 
     * @type {{ [key: string]: NonSubscription; }}
     * @memberof Subscriber
     */
    nonSubscriptions?: { [key: string]: NonSubscription; } | null;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Subscriber
     */
    otherPurchases?: { [key: string]: object; } | null;
}

export function SubscriberFromJSON(json: any): Subscriber {
    return SubscriberFromJSONTyped(json, false);
}

export function SubscriberFromJSONTyped(json: any, ignoreDiscriminator: boolean): Subscriber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstSeen': !exists(json, 'first_seen') ? undefined : (new Date(json['first_seen'])),
        'lastSeen': !exists(json, 'last_seen') ? undefined : (new Date(json['last_seen'])),
        'managementUrl': !exists(json, 'management_url') ? undefined : json['management_url'],
        'originalAppUserId': !exists(json, 'original_app_user_id') ? undefined : json['original_app_user_id'],
        'originalApplicationVersion': !exists(json, 'original_application_version') ? undefined : json['original_application_version'],
        'originalPurchaseDate': !exists(json, 'original_purchase_date') ? undefined : (json['original_purchase_date'] === null ? null : new Date(json['original_purchase_date'])),
        'entitlements': !exists(json, 'entitlements') ? undefined : (json['entitlements'] === null ? null : mapValues(json['entitlements'], EntitlementFromJSON)),
        'subscriptions': !exists(json, 'subscriptions') ? undefined : (json['subscriptions'] === null ? null : mapValues(json['subscriptions'], SubscriptionFromJSON)),
        'nonSubscriptions': !exists(json, 'non_subscriptions') ? undefined : (json['non_subscriptions'] === null ? null : mapValues(json['non_subscriptions'], NonSubscriptionFromJSON)),
        'otherPurchases': !exists(json, 'other_purchases') ? undefined : json['other_purchases'],
    };
}

export function SubscriberToJSON(value?: Subscriber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_seen': value.firstSeen === undefined ? undefined : (value.firstSeen.toISOString()),
        'last_seen': value.lastSeen === undefined ? undefined : (value.lastSeen.toISOString()),
        'management_url': value.managementUrl,
        'original_app_user_id': value.originalAppUserId,
        'original_application_version': value.originalApplicationVersion,
        'original_purchase_date': value.originalPurchaseDate === undefined ? undefined : (value.originalPurchaseDate === null ? null : value.originalPurchaseDate.toISOString()),
        'entitlements': value.entitlements === undefined ? undefined : (value.entitlements === null ? null : mapValues(value.entitlements, EntitlementToJSON)),
        'subscriptions': value.subscriptions === undefined ? undefined : (value.subscriptions === null ? null : mapValues(value.subscriptions, SubscriptionToJSON)),
        'non_subscriptions': value.nonSubscriptions === undefined ? undefined : (value.nonSubscriptions === null ? null : mapValues(value.nonSubscriptions, NonSubscriptionToJSON)),
        'other_purchases': value.otherPurchases,
    };
}


