/* tslint:disable */
/* eslint-disable */
/**
 * Vocalise.API.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Subscriber,
    SubscriberFromJSON,
    SubscriberToJSON,
} from '../models';

export interface ApiRevenuecatGetcustomerAppUserIdGetRequest {
    appUserId: string;
}

/**
 * 
 */
export class RevenueCatApi extends runtime.BaseAPI {

    /**
     */
    async apiRevenuecatGetcustomerAppUserIdGetRaw(requestParameters: ApiRevenuecatGetcustomerAppUserIdGetRequest): Promise<runtime.ApiResponse<Subscriber>> {
        if (requestParameters.appUserId === null || requestParameters.appUserId === undefined) {
            throw new runtime.RequiredError('appUserId','Required parameter requestParameters.appUserId was null or undefined when calling apiRevenuecatGetcustomerAppUserIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/revenuecat/getcustomer/{app_user_id}`.replace(`{${"app_user_id"}}`, encodeURIComponent(String(requestParameters.appUserId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriberFromJSON(jsonValue));
    }

    /**
     */
    async apiRevenuecatGetcustomerAppUserIdGet(requestParameters: ApiRevenuecatGetcustomerAppUserIdGetRequest): Promise<Subscriber> {
        const response = await this.apiRevenuecatGetcustomerAppUserIdGetRaw(requestParameters);
        return await response.value();
    }

}
