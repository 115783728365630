// eslint-disable-next-line import/no-duplicates
import enLocale from 'date-fns/locale/en-US'
// eslint-disable-next-line import/no-duplicates
import svLocale from 'date-fns/locale/sv'
import { getLocaleCollection } from 'api/contentful-management-api'
import { getLocalStorageFor, setLocalStorageForKey } from 'utilities/use-local-storage'

//KEY OF LOCALE IN LOCAL STORAGE
const LOCALE_KEY = 'DEFAULT_LOCALE'

const getLocale = async () => {
  const localStorageLocale = getLocalStorageFor<string>(LOCALE_KEY)
  if (localStorageLocale) return localStorageLocale
  try {
    const localeCollection = await getLocaleCollection()
    const locale = localeCollection.find((lc) => lc.code === navigator.language)
    let defaultLocale = ''
    if (locale) {
      defaultLocale = locale.code
    } else {
      defaultLocale = localeCollection[0].code
    }
    setLocalStorageForKey(LOCALE_KEY, defaultLocale)
    return defaultLocale
  } catch (error) {
    return 'en-US'
  }
}

const setLocale = (locale: string) => {
  setLocalStorageForKey(LOCALE_KEY, locale)
}

const localeMap: { [key in string]: Locale } = {
  'sv-SE': svLocale,
  'en-US': enLocale,
}
const getLocaleFns = (locale: string) => localeMap[locale]

export { getLocale, getLocaleFns, setLocale }
