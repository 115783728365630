import { useTheme } from '@material-ui/core'
import * as React from 'react'
import Div100vh from 'react-div-100vh'
import { css } from 'styled-components'
import { Spinner } from 'components/spinner'
import { isInStandaloneMode } from 'utilities/is-in-standalone-mode'
import { isIos } from 'utilities/is-ios'

const PageView: React.FC<{
  iosStatusbarColor?: string
  bottomNavbarComponent?: false | React.ReactNode
  bgcolor?: string
  appBarComponent?: false | React.ReactNode
  className?: string
  dense?: boolean
}> = ({
  children,
  iosStatusbarColor,
  className,
  bottomNavbarComponent: BottomNavbarComponent,
  bgcolor,
  appBarComponent: AppBarComponent = false,
  dense = false,
}) => {
  const theme = useTheme()

  return (
    <Div100vh
      css={css`
        & > *:first-child {
          ${isIos() && isInStandaloneMode()
            ? css`
                /* Older browsers (do now support CSS Environment variables) */
                padding-top: 0px;

                /* Browsers which partially support CSS Environment variables (iOS 11.0-11.2) */
                @supports (padding-top: constant(safe-area-inset-top)) {
                  padding-top: constant(safe-area-inset-top);
                }

                /* Browsers which fully support CSS Environment variables (iOS 11.2+) */
                @supports (padding-top: env(safe-area-inset-top)) {
                  padding-top: env(safe-area-inset-top);
                }
              `
            : ''}
        }

        background-color: ${bgcolor ?? theme.palette.background.default};
        display: flex;
        flex-direction: column;
      `}>
      <div
        css={css`
          //set maximu width 375px
          max-width: 375px;
          //make it center
          margin: 0px auto;
          display: flex;
          flex-direction: column;
          flex: 1;
          overflow-y: auto;
        `}>
        <div
          className={className}
          css={css`
            flex: 1;
            margin: 0 ${theme.spacing(dense ? 1 : 5)}px;

            padding-top: ${theme.spacing(dense ? 1 : 2)}px;
            @media (min-height: 540px) {
              padding-top: ${theme.spacing(dense ? 1 : 4)}px;
            }
          `}>
          <React.Suspense fallback={<Spinner />}>{children}</React.Suspense>
        </div>
      </div>
    </Div100vh>
  )
}

export default PageView
