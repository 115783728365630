import { useAuth0 } from '@auth0/auth0-react'
import * as React from 'react'
import { useMutation, useQuery, queryCache } from 'react-query'
import { UserApi } from 'api/apis'
import { UserDTO } from 'api/models'
import { PaymentRoute } from 'config/routes'
import { FIRST_NAME_KEY } from 'pages/create-account-page/create-account-page-view'
import { ResponseError } from 'types'
import { sendNewSubscriber } from 'utilities/mailchimp'
import { navigate } from 'utilities/react-router-hooks'
import { getLocalStorageFor } from 'utilities/use-local-storage'
import { configuration } from './utilities'

const api = new UserApi(configuration)

const BASE_KEY = 'user'

function useProfile() {
  const { isLoading: isLoadingAuth, isAuthenticated, user } = useAuth0()
  const enabled = isAuthenticated && !isLoadingAuth && user !== undefined

  const {
    data: profile,
    isLoading: isGetLoading,
    isSuccess: isGetSuccess,
    isError: isGetError,
    refetch,
  } = useQuery(
    BASE_KEY,
    () => {
      return api.apiUserGet().catch((error: ResponseError) => {
        if (error.status === 404) {
          const firstName = getLocalStorageFor(FIRST_NAME_KEY) as string | null
          return api.apiUserPost({
            userItem: { email: user.email, firstName: firstName || 'empty' },
          })
        }
        throw error
      })
    },
    {
      enabled,
    }
  )

  return React.useMemo(
    () => ({
      profile,
      refetch,
      isLoading: isGetLoading || isLoadingAuth,
      isError: isGetError,
      isSuccess: isGetSuccess,
    }),
    [isGetError, isGetLoading, isGetSuccess, isLoadingAuth, profile, refetch]
  )
}

function useCreate() {
  const [createProfile, result] = useMutation(api.apiUserPost.bind(api), {
    throwOnError: true,
    useErrorBoundary: false,
    onSuccess: (data) => {
      setProfileData(data)
      sendNewSubscriber(data.email ?? '', 'Vocalise user')
      navigate(PaymentRoute.generatePath())
    },
  })

  const create = React.useCallback((userItem: UserDTO) => createProfile({ userItem }), [
    createProfile,
  ])

  return React.useMemo(() => ({ create, result }), [create, result])
}

const getProfileData = () => {
  return queryCache.getQueryData<UserDTO>(BASE_KEY)
}

const setProfileData = (dataOrUpdater: UserDTO | ((oldData: UserDTO | undefined) => UserDTO)) => {
  queryCache.setQueryData(BASE_KEY, dataOrUpdater)
}

const forceRefetchProfile = () => {
  queryCache.invalidateQueries(BASE_KEY)
}

export const profileCache = {
  setProfileData,
  getProfileData,
  forceRefetchProfile,
}

export const profileApi = api

const ProfileQuery = {
  useProfile,
  useCreate,
}
export default ProfileQuery
