import * as React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Route, Router, Switch } from 'react-router'
import { ErrorFallback } from 'components/error-fallback'
import { Spinner } from 'components/spinner'
import {
  NotFoundRoute,
  DashboardRoute,
  PreviewSessionRoute,
  StepRoute,
  OnboardingRoute,
  SettingRoute,
  WelcomeRoute,
  PaymentRoute,
  TryOutRoute,
  PaymentSuccessRoute,
  PaymentCancelRoute,
  TryOutStepRoute,
  CreateAccountRoute,
  LibraryRoute,
  VoucherRoute,
  RedirectRoute,
} from 'config/routes'
import { browserHistory } from 'utilities/history'
import { MixpanelProvider } from 'utilities/mixpanel-context'
import { PrivateRoute, PublicRoute } from './components'

const RouterContainer: React.FC = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => window.location.reload()}>
      <React.Suspense fallback={<Spinner />}>
        <Router history={browserHistory}>
          <Switch>
            <PublicRoute title="Vocalise Onboarding" {...OnboardingRoute.props} />
            <PublicRoute title="Vocalise Try Out List" {...TryOutRoute.props} />
            <PublicRoute title="Vocalise Try Out" {...TryOutStepRoute.props} />
            <PublicRoute title="Vocalise Create Account" {...CreateAccountRoute.props} />
            <MixpanelProvider>
              <PrivateRoute title="Vocalise Welcome" {...WelcomeRoute.props} />
              <PrivateRoute title="Vocalise Purchase" {...PaymentRoute.props} />
              <PrivateRoute title="Vocalise Purchase Completed" {...PaymentSuccessRoute.props} />
              <PrivateRoute title="Vocalise Purchase Canceled" {...PaymentCancelRoute.props} />
              <PrivateRoute title="Vocalise Dashboard" {...DashboardRoute.props} />
              <PrivateRoute title="Vocalise Session" {...StepRoute.props} />
              <PrivateRoute title="Vocalise Preview Session" {...PreviewSessionRoute.props} />
              <PrivateRoute title="Vocalise Settings" {...SettingRoute.props} />
              <PrivateRoute title="Vocalise Library" {...LibraryRoute.props} />
              <PrivateRoute title="Vocalise Voucher" {...VoucherRoute.props} />
              <PrivateRoute title="Redirect" {...RedirectRoute.props} />
            </MixpanelProvider>
            <Route {...NotFoundRoute.props} />
          </Switch>
        </Router>
      </React.Suspense>
    </ErrorBoundary>
  )
}

export default RouterContainer
