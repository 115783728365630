/* tslint:disable */
/* eslint-disable */
/**
 * Vocalise.API.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NonSubscription
 */
export interface NonSubscription {
    /**
     * 
     * @type {string}
     * @memberof NonSubscription
     */
    id?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof NonSubscription
     */
    purchaseDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof NonSubscription
     */
    store?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NonSubscription
     */
    isSandbox?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NonSubscription
     */
    storeTransactionId?: string | null;
}

export function NonSubscriptionFromJSON(json: any): NonSubscription {
    return NonSubscriptionFromJSONTyped(json, false);
}

export function NonSubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): NonSubscription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'purchaseDate': !exists(json, 'purchase_date') ? undefined : (new Date(json['purchase_date'])),
        'store': !exists(json, 'store') ? undefined : json['store'],
        'isSandbox': !exists(json, 'is_sandbox') ? undefined : json['is_sandbox'],
        'storeTransactionId': !exists(json, 'store_transaction_id') ? undefined : json['store_transaction_id'],
    };
}

export function NonSubscriptionToJSON(value?: NonSubscription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'purchase_date': value.purchaseDate === undefined ? undefined : (value.purchaseDate.toISOString()),
        'store': value.store,
        'is_sandbox': value.isSandbox,
        'store_transaction_id': value.storeTransactionId,
    };
}


