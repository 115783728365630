/* tslint:disable */
/* eslint-disable */
/**
 * Vocalise.API.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Entitlement
 */
export interface Entitlement {
    /**
     * 
     * @type {Date}
     * @memberof Entitlement
     */
    expiresDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Entitlement
     */
    gracePeriodExpiresDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Entitlement
     */
    purchaseDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Entitlement
     */
    productIdentifier?: string | null;
}

export function EntitlementFromJSON(json: any): Entitlement {
    return EntitlementFromJSONTyped(json, false);
}

export function EntitlementFromJSONTyped(json: any, ignoreDiscriminator: boolean): Entitlement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expiresDate': !exists(json, 'expires_date') ? undefined : (new Date(json['expires_date'])),
        'gracePeriodExpiresDate': !exists(json, 'grace_period_expires_date') ? undefined : (json['grace_period_expires_date'] === null ? null : new Date(json['grace_period_expires_date'])),
        'purchaseDate': !exists(json, 'purchase_date') ? undefined : (new Date(json['purchase_date'])),
        'productIdentifier': !exists(json, 'product_identifier') ? undefined : json['product_identifier'],
    };
}

export function EntitlementToJSON(value?: Entitlement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expires_date': value.expiresDate === undefined ? undefined : (value.expiresDate.toISOString()),
        'grace_period_expires_date': value.gracePeriodExpiresDate === undefined ? undefined : (value.gracePeriodExpiresDate === null ? null : value.gracePeriodExpiresDate.toISOString()),
        'purchase_date': value.purchaseDate === undefined ? undefined : (value.purchaseDate.toISOString()),
        'product_identifier': value.productIdentifier,
    };
}


