/* tslint:disable */
/* eslint-disable */
/**
 * Vocalise.API.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * 
     * @type {Date}
     * @memberof Subscription
     */
    expiresDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Subscription
     */
    purchaseDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Subscription
     */
    originalPurchaseDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    ownershipType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    periodType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    store?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Subscription
     */
    isSandbox?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Subscription
     */
    unsubscribeDetectedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Subscription
     */
    billingIssuesDetectedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Subscription
     */
    gracePeriodExpiresDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Subscription
     */
    refundedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Subscription
     */
    autoResumeDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    storeTransactionId?: string | null;
}

export function SubscriptionFromJSON(json: any): Subscription {
    return SubscriptionFromJSONTyped(json, false);
}

export function SubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Subscription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expiresDate': !exists(json, 'expires_date') ? undefined : (new Date(json['expires_date'])),
        'purchaseDate': !exists(json, 'purchase_date') ? undefined : (new Date(json['purchase_date'])),
        'originalPurchaseDate': !exists(json, 'original_purchase_date') ? undefined : (new Date(json['original_purchase_date'])),
        'ownershipType': !exists(json, 'ownership_type') ? undefined : json['ownership_type'],
        'periodType': !exists(json, 'period_type') ? undefined : json['period_type'],
        'store': !exists(json, 'store') ? undefined : json['store'],
        'isSandbox': !exists(json, 'is_sandbox') ? undefined : json['is_sandbox'],
        'unsubscribeDetectedAt': !exists(json, 'unsubscribe_detected_at') ? undefined : (json['unsubscribe_detected_at'] === null ? null : new Date(json['unsubscribe_detected_at'])),
        'billingIssuesDetectedAt': !exists(json, 'billing_issues_detected_at') ? undefined : (json['billing_issues_detected_at'] === null ? null : new Date(json['billing_issues_detected_at'])),
        'gracePeriodExpiresDate': !exists(json, 'grace_period_expires_date') ? undefined : (json['grace_period_expires_date'] === null ? null : new Date(json['grace_period_expires_date'])),
        'refundedAt': !exists(json, 'refunded_at') ? undefined : (json['refunded_at'] === null ? null : new Date(json['refunded_at'])),
        'autoResumeDate': !exists(json, 'auto_resume_date') ? undefined : (json['auto_resume_date'] === null ? null : new Date(json['auto_resume_date'])),
        'storeTransactionId': !exists(json, 'store_transaction_id') ? undefined : json['store_transaction_id'],
    };
}

export function SubscriptionToJSON(value?: Subscription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expires_date': value.expiresDate === undefined ? undefined : (value.expiresDate.toISOString()),
        'purchase_date': value.purchaseDate === undefined ? undefined : (value.purchaseDate.toISOString()),
        'original_purchase_date': value.originalPurchaseDate === undefined ? undefined : (value.originalPurchaseDate.toISOString()),
        'ownership_type': value.ownershipType,
        'period_type': value.periodType,
        'store': value.store,
        'is_sandbox': value.isSandbox,
        'unsubscribe_detected_at': value.unsubscribeDetectedAt === undefined ? undefined : (value.unsubscribeDetectedAt === null ? null : value.unsubscribeDetectedAt.toISOString()),
        'billing_issues_detected_at': value.billingIssuesDetectedAt === undefined ? undefined : (value.billingIssuesDetectedAt === null ? null : value.billingIssuesDetectedAt.toISOString()),
        'grace_period_expires_date': value.gracePeriodExpiresDate === undefined ? undefined : (value.gracePeriodExpiresDate === null ? null : value.gracePeriodExpiresDate.toISOString()),
        'refunded_at': value.refundedAt === undefined ? undefined : (value.refundedAt === null ? null : value.refundedAt.toISOString()),
        'auto_resume_date': value.autoResumeDate === undefined ? undefined : (value.autoResumeDate === null ? null : value.autoResumeDate.toISOString()),
        'store_transaction_id': value.storeTransactionId,
    };
}


